import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller, Mousewheel } from "swiper";
import Classnames from "classnames";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import "./index.scss";
import "swiper/css";
import "swiper/css/mousewheel";
export default function Home() {
    const [mySwiper, setMySwiper] = useState(null);
    const [index, setIndex] = useState(0);
    const [wrapperStyle, setWrapperStyle] = useState({ transform: "translate3d(-50%, -50%, 0px) scale(1)" });
    const [showRegular, setShowRegular] = useState(false);
    const [pkgName, setPkgName] = useState("pchz9");
    console.log(pkgName);
    const slideToIndex = (index) => {
        console.log(mySwiper);
        mySwiper.slideTo(index);
    };
    const loadUrl = (url) => {
        window.open(url);
    };
    const toDownload = (num) => {
        var _a;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const pkg = urlParams.get("pkg");
        (_a = window === null || window === void 0 ? void 0 : window.ns) === null || _a === void 0 ? void 0 : _a.call(window, "send", "event", "wtcg_box_client", "clk_new_2_391_11", JSON.stringify({ button_type: num, page_key: index + 1, utm_source: pkg }));
        window.open(`https://hs-api.lushi.163.com/setup/${pkgName}/hsa_setup.exe`);
    };
    useEffect(() => {
        var _a;
        setShowRegular(false);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const pkg = urlParams.get("pkg");
        (_a = window === null || window === void 0 ? void 0 : window.ns) === null || _a === void 0 ? void 0 : _a.call(window, "send", "event", "wtcg_box_client", "exposure_new_2_391_12", JSON.stringify({ page_key: index + 1, utm_source: pkg }));
    }, [index]);
    useEffect(() => {
        console.log(window.innerWidth);
        const rateWidth = window.innerWidth / 3840;
        const rateHeight = window.innerHeight / 2160;
        setWrapperStyle({ transform: `translate3d(-50%, -50%, 0px) scale(${rateWidth < rateHeight ? rateWidth : rateHeight})` });
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const pkg = urlParams.get("pkg");
        setPkgName(pkg !== null && pkg !== void 0 ? pkg : "pchz9");
        console.log("pkg", pkg);
    }, []);
    const tooltip = (React.createElement("div", { className: "home-header-right-mobile-qrcode" }));
    return (React.createElement("div", { className: "home-view" },
        React.createElement("div", { className: "home-main", style: wrapperStyle },
            React.createElement("div", { className: "home-header" },
                React.createElement("div", { className: "home-header-inner" },
                    React.createElement("div", { className: "home-header-left" },
                        React.createElement("div", { className: "home-logo" },
                            React.createElement("i", null, "\u5185\u6D4B")),
                        React.createElement("div", { className: Classnames("tab-item", { active: index === 0 }), onClick: () => slideToIndex(0) }, "\u9996\u9875"),
                        React.createElement("div", { className: Classnames("tab-item", { active: index === 1 }), onClick: () => slideToIndex(1) }, "\u5361\u7EC4\u901F\u9012"),
                        React.createElement("div", { className: Classnames("tab-item", { active: index === 2 }), onClick: () => slideToIndex(2) }, "\u667A\u80FD\u8F85\u52A9")),
                    React.createElement("div", { className: "home-header-right" },
                        React.createElement(Tooltip, { destroyTooltipOnHide: true, overlay: tooltip, overlayClassName: "qrcode-tooltip", placement: "bottom" },
                            React.createElement("a", { className: "home-header-right-mobile" }, "\u79FB\u52A8\u7AEF\u4E0B\u8F7D")),
                        React.createElement("a", { className: "home-header-right-pc", onClick: () => toDownload(2) },
                            React.createElement("i", null),
                            "\u5BA2\u6237\u7AEF\u4E0B\u8F7D")))),
            React.createElement("div", { className: "home-steps" },
                React.createElement("div", { className: "home-steps-container" },
                    React.createElement("div", { className: Classnames("home-step-item", { active: index === 0 }) },
                        React.createElement("div", { className: "home-step-dot", onClick: () => slideToIndex(0) })),
                    React.createElement("div", { className: Classnames("home-step-item", { active: index === 1 }), style: { height: "50%" } },
                        React.createElement("div", { className: "home-step-line" }),
                        React.createElement("div", { className: "home-step-dot", onClick: () => slideToIndex(1) })),
                    React.createElement("div", { className: Classnames("home-step-item", { active: index === 2 }), style: { height: "50%" } },
                        React.createElement("div", { className: "home-step-line" }),
                        React.createElement("div", { className: "home-step-dot", onClick: () => slideToIndex(2) })))),
            React.createElement("div", { className: "home-swiper-wrapper" },
                React.createElement(Swiper, { className: "swiper-wrapper pr", controller: { control: mySwiper }, direction: "vertical", modules: [Controller, Mousewheel], mousewheel: true, autoplay: true, onSlideChangeTransitionEnd: e => setIndex(e.realIndex), onSwiper: setMySwiper },
                    React.createElement(SwiperSlide, { key: "0" },
                        React.createElement("div", { className: "home-swiper-item first" },
                            React.createElement("a", { className: "home-swiper-download", onClick: () => toDownload(1) }),
                            React.createElement("div", { className: "home-swiper-qrcode-download" },
                                React.createElement("div", { className: "home-swiper-qrcode-wrap" },
                                    React.createElement("i", null)),
                                React.createElement("div", null,
                                    React.createElement("a", { className: "home-swiper-apple", href: "https://xm.gameyw.netease.com/wyds_dl_lushibox" }),
                                    React.createElement("a", { className: "home-swiper-andro", href: "https://xm.gameyw.netease.com/wyds_dl_lushibox" }))))),
                    React.createElement(SwiperSlide, { key: "1" },
                        React.createElement("div", { className: "home-swiper-item second" })),
                    React.createElement(SwiperSlide, { key: "2" },
                        React.createElement("div", { className: "home-swiper-item third" })))),
            React.createElement("div", { className: Classnames("home-scroll-tips", { hidden: index === 2 }) }),
            React.createElement("div", { className: "home-regular-link", onClick: () => setShowRegular(true) },
                "\u67E5\u770B\u7248\u6743\u4FE1\u606F",
                React.createElement("i", null)),
            React.createElement("div", { className: Classnames("home-regular-main", { hidden: !showRegular }) },
                React.createElement("div", { className: "copyright-logo" },
                    React.createElement("i", { className: "icon-netease" }),
                    React.createElement("i", { className: "line-red" }),
                    React.createElement("i", { className: "icon-netease-game" })),
                React.createElement("div", { className: "copyright-nav" },
                    React.createElement("strong", null, "\u5173\u4E8E\u7F51\u6613"),
                    React.createElement("p", null,
                        React.createElement("span", { className: "copyright-nav-item", onClick: () => loadUrl("http://gb.corp.163.com/gb/about/overview.html") }, "\u516C\u53F8\u7B80\u4ECB"),
                        React.createElement("span", { className: "copyright-nav-item", onClick: () => loadUrl("https://help.mail.163.com/service.html") }, "\u5BA2\u6237\u670D\u52A1"),
                        React.createElement("span", { className: "copyright-nav-item", onClick: () => loadUrl("https://game.163.com/about/") }, "\u7F51\u6613\u6E38\u620F"),
                        React.createElement("span", { className: "copyright-nav-item", onClick: () => loadUrl("https://game.163.com/contact/business.html") }, "\u5546\u52A1\u5408\u4F5C"),
                        React.createElement("span", { className: "copyright-nav-item", onClick: () => loadUrl("https://hr.game.163.com/index.html") }, "\u52A0\u5165\u6211\u4EEC"),
                        React.createElement("span", { className: "copyright-nav-item", onClick: () => loadUrl("https://jiazhang.gm.163.com/") }, "\u7F51\u6613\u5BB6\u957F\u5173\u7231\u5E73\u53F0"),
                        React.createElement("span", { className: "copyright-nav-item", onClick: () => loadUrl("https://game.16163.com/report/") }, "\u8FDD\u6CD5\u548C\u4E0D\u826F\u4FE1\u606F\u4E3E\u62A5\u4E2D\u5FC3")),
                    React.createElement("p", { className: "copyright-nav-item", onClick: () => loadUrl("https://hs-web.lushi.163.com/privacy-policy.html") }, "\u300A\u7F51\u6613\u7089\u77F3\u76D2\u5B50\u9690\u79C1\u653F\u7B56\u53CA\u513F\u7AE5\u4E2A\u4EBA\u4FE1\u606F\u4FDD\u62A4\u89C4\u5219\u300B"),
                    React.createElement("p", { className: "copyright-nav-item", onClick: () => loadUrl("https://game.163.com/fcm/") }, "\u300A\u7F51\u7EDC\u6E38\u620F\u884C\u4E1A\u9632\u6C89\u8FF7\u81EA\u5F8B\u516C\u7EA6\u300B")),
                React.createElement("div", { className: "copyright-info" },
                    React.createElement("strong", null, "Copyright"),
                    React.createElement("p", null, "\u7F51\u6613\u516C\u53F8\u7248\u6743\u6240\u6709\u00A91997-2024 "),
                    React.createElement("p", { className: "copyright-nav-item", onClick: () => loadUrl("https://beian.miit.gov.cn/#/Integrated/index") }, "\u5DE5\u4E1A\u548C\u4FE1\u606F\u5316\u90E8\u5907\u6848\u7BA1\u7406\u7CFB\u7EDF\u7F51\u7AD9 \u7CA4B2-20090191-18 "),
                    React.createElement("p", { className: "copyright-nav-item", onClick: () => loadUrl("https://cms-bucket.ws.126.net/2024/0606/ac25db36j00semz2h004bc000gj01wwc.jpg") }, "\u4FE1\u606F\u7F51\u7EDC\u4F20\u64AD\u89C6\u542C\u8282\u76EE\u8BB8\u53EF\u8BC1"),
                    React.createElement("p", null, "\u300A\u7F51\u7EDC\u6587\u5316\u7ECF\u8425\u8BB8\u53EF\u8BC1\u300B \u7CA4\u7F51\u6587[2014]0636-236\u53F7"))),
            React.createElement("div", { className: "loadpic" }))));
}
