import React from "react";
import { createRoot } from "react-dom/client";
import { getBrowserType } from "./utils/browser";
import "./assets/css/reset.css";
import "./index.scss";
import Home from "./views/home";
import Mobile from "./views/mobile";
function App() {
    const { isAndroid, isIOS } = getBrowserType();
    const isMobile = isAndroid || isIOS;
    return (React.createElement("div", { className: "main-page pr" }, isMobile ? React.createElement(Mobile, null) : React.createElement(Home, null)));
}
console.log("应用构建环境：", process.env.NODE_ENV);
console.log("应用运行环境：", RUN_ENV);
const container = document.getElementById("root");
!!container && createRoot(container).render(React.createElement(App, null));
